<template>
   <div class="row">
      <div class="col-12">
         <div class="card">
            <div class="card-body">
               <div class="row">
                  <div class="wpx-250 mx-auto mx-md-0 mb-3 mb-md-0">
                     <img width="100%" class="rounded" :src="curso.nomeArquivo == null ? '' : curso.nomeArquivo" alt="banner" @error="imageError">
                  </div>
                  <div class="col-md py-2">
                     <div class="row">
                        <div class="col">
                           <h4 class="limitador">{{ curso.nome }}</h4>
                        </div>
                        <div class="w-max-content">
                           <i class="far fa-undo-alt font-20 cursor-pointer float-end" @click="fecharModulo"></i>
                        </div>
                     </div>
                     <p class="limitador-3"><i class="far fa-info-circle font-12 color-theme me-1"></i> {{ curso.descricao != null ? curso.descricao : 'Sem descrição' }}</p>
                     <span class="font-16"><i class="far fa-video color-theme font-14 me-1"></i> {{ totalAulas + ' aulas' }}</span>
                  </div>
               </div>
            </div>
         </div>
      </div>

      <div class="col-12" v-for="(bloco, index) in curso.blocos" :key="index">
         <div class="card mb-2">
            <div class="card-body">
               <div class="row align-items-center">
                  <div class="col">
                     <h4 class="limitador font-22 mb-0">{{ bloco.titulo }}</h4>
                  </div>
                  <div class="w-max-content">
                     <i class="fal fa-times font-20 text-danger cursor-pointer" @click="deleteBloco(bloco)"></i>
                  </div>
                  <div class="col-12 mt-2">
                     <p class="text-secondary font-17 mb-2"><i class="far fa-books font-14 me-1"></i> {{ $t('admModulo.aulas') }}</p>
                  </div>
                  <div class="col-12">
                     <div class="card mb-1" :class="index % 2 == 0 ? 'invert' : ''" v-for="(aula, index) in bloco.aulas" :key="index">
                        <div class="card-body p-12">
                           <div class="row align-items-center">
                              <div class="w-max-content">
                                 <i class="far fa-play color-theme font-16"></i>
                              </div>
                              <div class="col-md cursor-pointer" @click="editAula(aula, bloco.id)">
                                 <h6 class="font-16 mb-1">{{ aula.tituloAula }}</h6>
                                 <p class="font-14 limitador text-secondary mb-0">{{ aula.descricao }}</p>
                              </div>
                              <div class="w-max-content">
                                 <i class="far fa-trash font-14 text-danger cursor-pointer" @click="deleteAula(aula)"></i>
                              </div>
                           </div>
                        </div>                           
                     </div>
                     <div class="card hover invert cursor-pointer mb-1" @click="addAula(bloco)">
                        <div class="card-body">
                           <div class="row align-items-center">
                              <div class="w-max-content">
                                 <i class="far fa-plus color-theme font-16"></i>
                              </div>
                              <div class="col-md">
                                 <h6 class="font-16 mb-0">{{ $t('admModulo.addAula') }}</h6>
                              </div>
                           </div>
                        </div>                           
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div class="col-12">
         <div class="card hover invert cursor-pointer mb-2" @click="addBloco">
            <div class="card-body">
               <div class="row align-items-center">
                  <div class="w-max-content">
                     <i class="far fa-plus font-20 color-theme"></i>
                  </div>
                  <div class="col">
                     <h4 class="limitador font-16 mb-0">{{ $t('admModulo.addBloco') }}</h4>
                  </div>
               </div>
            </div>
         </div>
      </div>

      <!-- modalAddBloco -->
      <div class="modal fade" id="modalAddBloco" tabindex="-1" data-bs-backdrop="static" aria-labelledby="modalAddBlocoLabel" aria-hidden="true">
         <div class="modal-dialog modal-md modal-dialog-centered modal-dialog-scrollable" role="document">
            <div class="modal-content">
               <div class="modal-header">
                  <h5 class="modal-title" id="modalAddBlocoLabel" v-if="bloco != null && bloco.id != null">{{ $t('admModulo.editBloco') }}</h5>
                  <h5 class="modal-title" id="modalAddBlocoLabel" v-else>{{ $t('admModulo.addBloco') }}</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
               </div>
               <div class="modal-body">
                  <div class="row m-0">
                     <div class="col-12 px-1 mb-3">
                        <label><i class="far fa-file font-13 color-theme me-1"></i> {{ $t('admModulo.campoTitulo') }}</label>
                        <input type="text" class="form-control" maxlength="200" v-model="bloco.titulo"/>
                     </div>
                  </div>
               </div>
               <div class="modal-footer">
                  <button type="button" class="btn btn-primary" @click="saveBloco">{{ $t('admModulo.botaoSalvar') }}</button>
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ $t('admModulo.botaoFechar') }}</button>
               </div>
            </div>
         </div>
      </div>

      <!-- modalAddAula -->
      <div class="modal fade" id="modalAddAula" tabindex="-1" data-bs-backdrop="static" aria-labelledby="modalAddAulaLabel" aria-hidden="true">
         <div class="modal-dialog modal-md modal-dialog-centered modal-dialog-scrollable" role="document">
            <div class="modal-content h-100">
               <div class="modal-header">
                  <h5 class="modal-title" id="modalAddAulaLabel" v-if="aula != null && aula.idAula != null">{{ $t('admModulo.editAula') }}</h5>
                  <h5 class="modal-title" id="modalAddAulaLabel" v-else>{{ $t('admModulo.addAula') }}</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
               </div>
               <div class="modal-body">
                  <div class="card mb-2">
                     <div class="card-body">
                        <div class="row m-0">
                           <div class="col-12 px-1 mb-1">
                              <h6 class="text-center mb-0">{{ $t('admModulo.infoAulaTitulo') }}</h6>
                              <hr class="mb-2">
                           </div>
                           <div class="mb-3 px-1 col-12">
                              <label><i class="far fa-file font-13 color-theme me-1"></i> {{ $t('admModulo.campoTitulo') }}</label>
                              <input type="text" class="form-control rounded" maxlength="200" v-model="aula.tituloAula"/>
                           </div>
                           <div class="col-12 px-1">
                              <label><i class="far fa-tag font-13 color-theme me-1"></i> {{ $t('admModulo.campoDescricao') }}</label>
                              <textarea style="height: 200px;" class="form-control rounded" v-model="aula.descricao"></textarea>
                           </div>
                        </div>
                     </div>
                  </div>

                  <div class="card mb-2">
                     <div class="card-body">
                        <div class="row m-0">
                           <div class="col-12 px-1 mb-1">
                              <h6 class="text-center mb-0">{{ $t('admModulo.campoVideo') }}</h6>
                              <hr class="mb-2">
                           </div>
                           <div class="col-12 px-1 mb-3">
                              <label><i class="far fa-file font-13 color-theme me-1"></i> Link</label>
                              <input type="text" class="form-control rounded" placeholder="Src do Iframe" maxlength="500" v-model="aula.link"/>
                           </div>
                           <div class="col-12 px-1" v-if="String(aula.link).startsWith('https://www.youtube.com/embed/') || String(aula.link).startsWith('https://iframe.mediadelivery.net/embed/')">
                              <div class="ratio ratio-16x9">
                                 <iframe class="rounded" width="100%" height="550" :src="aula.link" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media;" allowfullscreen></iframe>
                              </div>
                           </div>
                           <div class="col-12 px-1 my-5 text-center" v-else>{{ $t('admModulo.nenhumVideo') }}</div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="modal-footer">
                  <button type="button" class="btn btn-primary" @click="saveAula">{{ $t('admModulo.botaoSalvar') }}</button>
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ $t('admModulo.botaoFechar') }}</button>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

import $ from 'jquery'
import Swal from 'sweetalert2'
import { mapState } from 'vuex'

export default {
	name: 'Modulo',
	data: function () {
		return {
         curso: {},
         bloco: {'idModulo': null, 'id': null, 'titulo': null},
         aula: {'idAula': null, 'tituloAula': null, 'descricao': null, 'link': null, 'data': null, 'bloco': null},
         moduloAtual: null,
         totalAulas: 0,
         totalAssistidas: 0
		}
	},
   watch: {
      'aula.link': function () {
			const regex = /src="([^"]*)"/;
         const match = this.aula.link.match(regex);
         if (match && match.length > 1) {
            this.aula.link = match[1];
         }
		}
   },
	computed: {
		...mapState({
			dadosUsuario:  state => state.dadosUsuario,
         urlRest: state => state.urlRest
		})
	},
	methods: {
      imageError : function (e) {
         this.$store.dispatch('imageError', e)
      },
		searchAllFromModulo : function () {
         this.$store.dispatch('alternarTelaCarregamento', true)
         this.curso = {};

         this.$axios({
            method: 'get',
            url: this.urlRest +'servicosAdmin/searchAllFromModulo',
            params: {
               id: this.moduloAtual
            }
         }).then(response => {
				this.curso = response.data;
            this.calcularAulas()

         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  this.$store.dispatch('deslogar')

                  this.$toast.fire({
                     icon: 'warning',
                     title: this.$t('sessaoExpirada')
                  });
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  });
               }
            } else {
               this.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         });
      },
      addBloco : function () {
         this.bloco.idModulo = this.moduloAtual
         this.bloco.titulo = null
         this.bloco.id = null
         $('#modalAddBloco').modal('show')
      },
      editBloco : function (bloco) {
         this.bloco.idModulo = this.moduloAtual
         this.bloco.titulo = bloco.titulo
         this.bloco.id = bloco.id
         $('#modalAddBloco').modal('show')
      },
      saveBloco : function () {
         if (this.bloco.titulo == null || this.bloco.titulo.trim().length == 0) {
            this.$toast.fire({
               icon: 'error',
               title: this.$t('admModulo.validacaoTitulo')
            })

            return
         }

			this.$store.dispatch('alternarTelaCarregamento', true)

         this.$axios({
            method: 'post',
            url: this.urlRest +'servicosAdmin/saveBloco',
            headers: {
					'Content-Type': 'application/json'
            },
				data: this.bloco

         }).then(() => {
            this.searchAllFromModulo()

         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  this.$store.dispatch('deslogar')

                  this.$toast.fire({
                     icon: 'warning',
                     title: this.$t('sessaoExpirada')
                  });
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  })
               }
            } else {
               this.$toast.fire({
                  icon: 'error',
                  title: error
               })
            }
         }).finally(() => {
            $('#modalAddBloco').modal('hide')
            this.$store.dispatch('alternarTelaCarregamento', false)
         })
      },
      deleteBloco : function (bloco) {
         Swal.fire({
            icon: 'warning',
            title: this.$t('admModulo.deletarBlocoDesc1'),
            text: this.$t('admModulo.deletarBlocoDesc2'),
            showCancelButton: true,
            confirmButtonText: this.$t('admModulo.botaoConfirmar'),
            cancelButtonText: this.$t('admModulo.botaoCancelar')

         }).then((result) => {
            if (result.isConfirmed) {
               this.$store.dispatch('alternarTelaCarregamento', true)
               
               this.$axios({
                  method: 'post',
                  url: this.urlRest +'servicosAdmin/deleteBloco',
                  params: {
                     id: bloco.id
                  }
               }).then(() => {
                  this.searchAllFromModulo()

                  this.$toast.fire({
                     icon: 'success',
                     title: this.$t('admModulo.deletarBlocoSucesso')
                  })
               }).catch((error) => {
                  if (error.response != undefined) {
                     if (error.response.status == 408) {
                        this.$store.dispatch('deslogar')

                        this.$toast.fire({
                           icon: 'warning',
                           title: this.$t('sessaoExpirada')
                        });
                     } else {
                        this.$toast.fire({
                           icon: 'error',
                           title: 'Erro: ' + error.response.status
                        })
                     }
                  } else {
                     this.$toast.fire({
                        icon: 'error',
                        title: error
                     })
                  }
               }).finally(() => {
                  this.$store.dispatch('alternarTelaCarregamento', false)
               })
            }
         });
      },
      fecharModulo : function () {
         this.$store.dispatch('alternarTelaCarregamento', true)
         this.$router.push('/admGerenciar');
         this.$store.dispatch('alternarTelaCarregamento', false)
      },
      addAula : function (bloco) {
         this.aula = {
            'idAula': null, 
            'tituloAula': null, 
            'descricao': null, 
            'link': null, 
            'data': null, 
            'bloco': bloco.id
         },
         $('#modalAddAula').modal('show')
      },
      saveAula : function () {
         if (this.aula.tituloAula == null || this.aula.tituloAula.trim().length == 0) {
            this.$toast.fire({
               icon: 'error',
               title: this.$t('admModulo.validacaoTitulo')
            })

            return
         }

			this.$store.dispatch('alternarTelaCarregamento', true)

         this.$axios({
            method: 'post',
            url: this.urlRest +'servicosAdmin/saveAula',
            headers: {
					'Content-Type': 'application/json'
            },
				data: this.aula

         }).then(() => {
            this.searchAllFromModulo()

         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  this.$store.dispatch('deslogar')

                  this.$toast.fire({
                     icon: 'warning',
                     title: this.$t('sessaoExpirada')
                  });
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  })
               }
            } else {
               this.$toast.fire({
                  icon: 'error',
                  title: error
               })
            }
         }).finally(() => {
            $('#modalAddAula').modal('hide')
            this.$store.dispatch('alternarTelaCarregamento', false)
         })
      },
      editAula : function (aula, idBloco) {
         this.aula = {
            'idAula': aula.idAula, 
            'tituloAula': aula.tituloAula, 
            'descricao': aula.descricao, 
            'link': aula.link, 
            'bloco': idBloco
         },

         $('#modalAddAula').modal('show')
      },
      deleteAula : function (aula) {
         Swal.fire({
            icon: 'warning',
            title: this.$t('admModulo.deletarAulaDesc'),
            showCancelButton: true,
            confirmButtonText: this.$t('admModulo.botaoConfirmar'),
            cancelButtonText: this.$t('admModulo.botaoCancelar')

         }).then((result) => {
            if (result.isConfirmed) {
               this.$store.dispatch('alternarTelaCarregamento', true)
               
               this.$axios({
                  method: 'post',
                  url: this.urlRest +'servicosAdmin/deleteAula',
                  params: {
                     id: aula.idAula
                  }
               }).then(() => {
                  this.searchAllFromModulo()

                  this.$toast.fire({
                     icon: 'success',
                     title: this.$t('admModulo.deletarAulaSucesso')
                  })
               }).catch((error) => {
                  if (error.response != undefined) {
                     if (error.response.status == 408) {
                        this.$store.dispatch('deslogar')

                        this.$toast.fire({
                           icon: 'warning',
                           title: this.$t('sessaoExpirada')
                        });
                     } else {
                        this.$toast.fire({
                           icon: 'error',
                           title: 'Erro: ' + error.response.status
                        })
                     }
                  } else {
                     this.$toast.fire({
                        icon: 'error',
                        title: error
                     })
                  }
               }).finally(() => {
                  this.$store.dispatch('alternarTelaCarregamento', false)
               })
            }
         });
      },
      calcularAulas : function () {
         for (let i = 0; i < this.curso.blocos.length; i++) {
            for (let h = 0; h < this.curso.blocos[i].aulas.length; h++) {
               this.totalAulas = this.totalAulas + 1
               if (this.curso.blocos[i].aulas[h].visualizado == true) this.totalAssistidas = this.totalAssistidas + 1
            }
         }
      }
	},
   mounted() {
      this.moduloAtual = this.$route.params.id;
      this.searchAllFromModulo(this.moduloAtual);
   }
}

</script>

<style scoped>

img {
	aspect-ratio: 3/4;
	object-fit: cover;
}

</style>